import './Wishlist.scss'
import React from "react";
import {useHistory, useParams} from "react-router-dom";
import {SHARED_WISHLIST} from '../PersonalArea/PersonalAreaWishlist/wishlistQuery';
import { useQuery } from '@apollo/react-hooks';
import { Link } from 'react-router-dom';
import { T } from '@deity-io/falcon-i18n';
import { Loader } from '@deity-io/falcon-ecommerce-uikit';


const extractGallery = (thumbnail, gallery) => {

    let firstImage = null;
    let thumbnailImage = null;
    let imageThumbnailLabel = null;
    let hoverImage = null;
    if (typeof gallery === 'object') {
        // Find the hover image
        hoverImage = gallery.find(x => x.is_hover === true);

        // Find the first image & get it's url
        const firstPosition = Math.min(...gallery.map(x => x.position));
        firstImage = gallery.find(x => x.position === firstPosition);
        firstImage = (firstImage && firstImage.medium_image_url) || (thumbnail && thumbnail.url);

        // Find if any image has been configured as the thumbnail and override the first image
        thumbnailImage = gallery.find(x => x.is_thumbnail === true);
        if (thumbnailImage) {
            firstImage = thumbnailImage.medium_image_url;
        }
        if (thumbnail && firstImage.label) {
            imageThumbnailLabel = firstImage.label;
        }
    }

    return {
        image: firstImage,
        imageLabel: imageThumbnailLabel,
        hoverImage
    }

}

const Wishlist = () => {
    const params = useParams()
    const history = useHistory();
    const { data, loading } = useQuery(SHARED_WISHLIST, {
        variables: {
            sharingCode: params.sharingCode
        }
    });

    if(loading) {
        return <Loader/>
    }

    const items = data.sharedWishlist.items;

    if(!items) {
        return <h1><T id="wishlist.failedToLoad"/></h1>
    }

    return(<div className="content">
            <h1><T id="wishlist.myWishlist"/></h1>
            <div>
                <table>
                    {items.map(({product}) => (
                        <tr>
                            <td>
                                <Link to={`/${product.canonical_url}`}>
                                    <img src={extractGallery(product.thumbnail, product.gallery).image}/>
                                    {product.name}
                                </Link>
                            </td>
                            <td><button onClick={() => history.push(`/${product.canonical_url}`)}>
                                <T id="wishlist.review"/>
                            </button></td>
                        </tr>
                    ))}
                </table>
            </div>
        </div>
    )
}

export default Wishlist;